import { useStaticQuery, graphql } from 'gatsby';
import { AllDirectusOnlineAdminPortal, OnlineAdminPortalEdges } from './online-admin-portal.interface';

export default (): OnlineAdminPortalEdges[] => {
    const { allDirectusOnlineAdminPortal } = useStaticQuery<AllDirectusOnlineAdminPortal>(graphql`
    query {
      allDirectusOnlineAdminPortal(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            sort
            title
            description
            width
            slug
            fontawesome_icon
            icon_color
            button_text
            button_link
            button_role
          }
        }
      }
    }
  `);

    return allDirectusOnlineAdminPortal.edges;
};
